import { useSuspense } from '@rest-hooks/react';
import { useHistory, useParams } from 'react-router-dom';

import EventResource from 'resources/Profile/EventResource';

const Next = () => {

  const { organizationSlug } = useParams();

  const history = useHistory();

  const nextEvent = useSuspense(EventResource.detail(), {organization_slug: organizationSlug, id: 'next'});

  history.push(`/${organizationSlug}/events/${nextEvent.slug}?openTicket=1`);

  return (null);
};

export default Next;
