import React from 'react';
import PropTypes from 'prop-types';

import AmountSelector from 'components/AmountSelector';

import Description from 'ui/text/Description';
import FormSection from 'ui/FormSection';

import { Checkbox, FormControlLabel, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';

const Content = ({onEmailChange, tickets, onTicketSelect, ticket, amount, onAmountChange, intent, checkedInStatus, onCheckedInStatusChange}) => {

  if (tickets.length === 0) return <FormSection slim><p>No tickets available</p></FormSection>;

  const handleTicketSelection = (event, ticket) => {
    onTicketSelect(ticket);
  };

  const handleEmailChange = ({target: {value}}) => {
    onEmailChange(value);
  };

  const handleCheckBoxChange = ({target: {checked}}) => {
    onCheckedInStatusChange(checked);
  };

  const emailField = <TextField
                        label={`Add Email ${intent === 'sell' ? '(optional)' : ''}`}
                        name="email"
                        onChange={handleEmailChange}
                        fullWidth
                        size='small'
                        color="warning"
                     />;

  return (
    <FormSection slim>
      <Description>
        {intent === "sell" 
          ? "Select ticket and quantity, and optionally enter an email to link to the sale." 
          : "Add an email below to send a new ticket."}
      </Description>

      {intent === "send" && emailField}

      <Autocomplete
        fullWidth
        options={tickets}
        onChange={(event, newValue) => {
          onAmountChange(1);
          handleTicketSelection(event, newValue);
        }}
        disabled={tickets.length === 1}
        defaultValue={tickets.find((element) => element.id === ticket?.id)}
        getOptionLabel={(option) => `${option.identifier} ${option.available_amount ? ' (' + option.available_amount + ' left)' : ''}`}
        disableClearable
        renderInput={(params) => (
            <TextField
                {...params}
                label="Ticket"
                name="ticket"
                fullWidth
                size='small'
                color="warning"
            />
        )}
      />

      {intent === "sell" && 
        <>
          <AmountSelector 
            amount={amount} 
            maxAmount={ticket.available_amount} 
            onAmountChange={onAmountChange} />
          <FormControlLabel
            sx={{ mt: -1 }}
            control={
              <Checkbox checked={checkedInStatus}
                        onChange={handleCheckBoxChange}
                        size='small'
              />
            }
            label="Mark as checked in"
          />
          {emailField}
        </>
      }

    </FormSection>
  );
};

Content.propTypes = {
  onEmailChange: PropTypes.func,
  tickets: PropTypes.array,
  onTicketSelect: PropTypes.func,
  ticket: PropTypes.object,
  amount: PropTypes.number,
  onAmountChange: PropTypes.func,
  intent: PropTypes.string,
  checkedInStatus: PropTypes.bool,
  onCheckedInStatusChange: PropTypes.func
};

export default Content;
