import React, { useState } from 'react';
import PropTypes from 'prop-types';

import CustomModal from './CustomModal';

import Alert from 'ui/Alert';

import QRCodeGenerator from "components/QRCodeGenerator";

import copyToClipboard from "utils/copyToClipboard";
import { useToast } from "utils/context/ToastContext";

import QrCodeIcon from "@mui/icons-material/QrCode";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const AlertCta = ({primaryText, externalUrl, qrCodeTitle, qrCodeimage}) => {

    const [customModalContent, setCustomModalContent] = useState(null);
    const { setOpen, setMessage, setSeverity } = useToast();

    const handleUrlCopy = () => {
        copyToClipboard(externalUrl, setOpen, setSeverity, setMessage)
    };

    const transformedPrimaryText = <>
        <span className="bold">{primaryText}</span>
        <a href={externalUrl} target="_blank" rel="noreferrer">{externalUrl}</a>
    </>

    const handleOpenQRCodeModal = () => {
        setCustomModalContent({
            title: qrCodeTitle,
            content: (
                <QRCodeGenerator
                    url={externalUrl}
                    image_url={qrCodeimage}
                />
            ),
        });
    };

  return (
    <>
        <Alert
            primaryText={transformedPrimaryText}
            primaryButtonIcon={ContentCopyIcon}
            primaryButtonAction={handleUrlCopy}
            secondaryButtonIcon={QrCodeIcon}
            secondaryButtonAction={handleOpenQRCodeModal}
        />

        {customModalContent && (
            <CustomModal
                handleClose={() => setCustomModalContent(null)}
                customModalContent={customModalContent}
            />
        )}
    </>
  );
};

AlertCta.propTypes = { 
    primaryText: PropTypes.string,
    externalUrl: PropTypes.func,
    qrCodeTitle: PropTypes.string,
    qrCodeimage: PropTypes.string
};

export default AlertCta;