import React from "react";
import PropTypes from "prop-types";

import { Checkbox, FormControlLabel } from "@mui/material";

const CheckboxElement = ({ticket, ticketIds, onTicketCheckboxChange}) => {
  return (
    <div key={ticket.id}>
      <FormControlLabel
        control={
          <Checkbox
            checked={ticketIds.includes(ticket.id)}
            onChange={() => onTicketCheckboxChange(ticket.id)}
          />
        }
        label={ticket.identifier}
      />
    </div>
  );
};

CheckboxElement.propTypes = {
  ticket: PropTypes.object.isRequired,
  ticketIds: PropTypes.array.isRequired,
  onTicketCheckboxChange: PropTypes.func.isRequired
};

export default CheckboxElement;