import React from "react";
import PropTypes from "prop-types";

import SearchableFilter from "ui/SearchableFilter";
import Subtitle from "ui/text/Subtitle";

const OrganizationAndEventSelector = ({ data, onDataChange }) => {
  const handleFilterSelection = (attribute, id) => {
    const newData = { ...data, [attribute]: id };
    onDataChange(newData);
  };

  return (
    <>
      <Subtitle small>Step 1: Select an organization and/or event</Subtitle>
      <div className="row">
        <SearchableFilter
          fullwidth
          label={"organization"}
          onChange={handleFilterSelection}
          customLabel="Select Organization"
        />
        <SearchableFilter
          fullwidth
          label={"event"}
          onChange={handleFilterSelection}
          customLabel="Select Event"
        />
      </div>
    </>
  );
};

OrganizationAndEventSelector.propTypes = {
  data: PropTypes.object,
  onDataChange: PropTypes.func,
};

export default OrganizationAndEventSelector;
