import ImportTicketsModal from "components/ImportTicketsModal";
import React, { useCallback, useState } from "react";

const useImportTicketsController = () => {
  const [open, setOpen] = useState(false);

  const handleImportRequest = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback((event, reason) => {
    if (reason === "backdropClick") return;
    setOpen(false);
  }, []);

  const ImportTicketsModalComponent = useCallback(() => {
    if (!open) return null;

    return <ImportTicketsModal open={open} onClose={handleClose} />;
  }, [open, handleClose]);

  return {
    handleImportRequest,
    ImportTicketsModalComponent,
  };
};

export default useImportTicketsController;
