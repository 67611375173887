export const getPageName = (path) => {
    const parts = path.replace(/^\/|\/$/g, "").split("/");
  
    if (parts.length === 1) return "Links";
  
    switch (parts[1]) {
      case "events":
        return parts[2] ? `Event: ${parts[2]}` : "Events";
      case "content":
        return parts[2] ? `Newsletter: ${parts[2]}` : "Newsletter";
      case "store":
        return parts[2] ? `Store: ${parts[2]}` : "Store";
      case "memberships":
        return "Memberships";
      default:
        return path;
    }
  };