import React from 'react';
import PropTypes from 'prop-types';

import CardGrid from './CardGrid';

import TagsFilter from 'components/TagsFilter';

const EventsList = ({events, organizationSlug, organizationTags, activeTag}) => {

  const showTags = organizationTags && organizationTags.length > 0;
  
  return (
    <div className='events-content'>
      {showTags &&
        <TagsFilter
          organizationSlug={organizationSlug}
          organizationTags={organizationTags}
          activeTag={activeTag} />}

      <CardGrid events={events} />
    </div>
  );
};

EventsList.propTypes = {
  events: PropTypes.array,
  organizationSlug: PropTypes.string,
  organizationTags: PropTypes.array,
  activeTag: PropTypes.string,
};

export default EventsList;
