import React, { useState, useEffect } from "react";
import { useController, useSuspense } from "@rest-hooks/react";
import PropTypes from 'prop-types';

import CheckInModalContent from "./CheckInModalContent";
import TicketFilter from "./TicketFilter";
import Header from "./Header";
import Stats from "./Stats";
import QRCodeScanner from "./QRCodeScanner";
import NewTicketModal from "./NewTicketModal.js";

import DataSelectionTable from "components/DataTable/DataSelectionTable";
import TabPanels from "components/TabPanels";
import CustomModal from "components/CustomModal";

import Button from "ui/Button";

import { useError } from "utils/useErrorController";
import { useToast } from "utils/context/ToastContext";
import { ticketsColumns } from "utils/tables/columns/ticketsColumns";
import usePaginationAndFilteringController from "utils/usePaginationAndFilteringController";
import useEndSalesController from 'utils/useEndSalesController';
import fetchTicket from "utils/fetchTicket";
import customToast from "utils/customToast";

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

const TicketsList = ({TicketResource, ticketResourceParams, event, sessionId=null}) => {

  const [customModalContent, setCustomModalContent] = useState(null);
  const [openNewTicketModal, setOpenNewTicketModal] = useState(false);
  const [intent, setIntent] = useState(null);

  const { fetch } = useController();
  const { handleError, setLoading } = useError();
  const { setOpen, setMessage, setSeverity } = useToast();

  const stats = useSuspense(TicketResource.list(), {...ticketResourceParams, stats: true});

  const {imported_tickets_count} = stats[0];

  const session = !event.single && event.sessions.find(session => session.id === +sessionId);
  const selectableTickets = session ? session.tickets : event.tickets;
  const filteredTickets = intent == "sell" 
                            ? selectableTickets.filter(ticket => ticket.sellable && ticket.available) 
                            : selectableTickets.filter(ticket => ticket.available);

  const {
    objectState,
    fetchData,
    handleFilterChange,
    handleQueryChange,
    handlePageChange
  } = usePaginationAndFilteringController({
      fetchDataResource: TicketResource,
      fetchDataParams: ticketResourceParams,
  })

  //fetchTickets on mount
  useEffect(() => {
    fetchData();
  }, [ticketResourceParams]);

  const handleCheckIn = async (ticket_uuid) => {
    try {
      setLoading(true);
      //repeat ticketResourceParams 
      //params are expected in the request url by the organization and in the request body by the checkin namespace controller
      await fetch(TicketResource.update(), { id: ticket_uuid, ...ticketResourceParams}, ticketResourceParams);
      setLoading(false);
      customToast( "success", "Check In Successful", setOpen, setSeverity, setMessage );
      fetchData();
      handleOpenQRCodeScanner();
    } catch (error) {
      handleError(error);
    }
  };

  const handleOpenTicketDetails = (ticket) => {
    setCustomModalContent({
      title: ticket.title,
      content: <CheckInModalContent ticket={ticket} />,
      button: (
        <Button
          disabled={ticket.completed_at}
          highlighted
          icon={CheckCircleOutlineIcon}
          onClick={() => handleCheckIn(ticket.uuid)}
          fullwidth
        >
          Check In
        </Button>
      ),
      fullScreen: true
    });
  };

  const onQRCodeRead = async (result) => {
    try {
      setLoading(true);
      const newSelectedTicket = await fetchTicket({result, ...ticketResourceParams});
      setLoading(false);
      handleOpenTicketDetails(newSelectedTicket);
    } catch (error) {
      setCustomModalContent(null);
      handleError(error);
    }
  };

  const handleOpenQRCodeScanner = () => {
    setCustomModalContent({
      title: "Check In",
      content: <QRCodeScanner onQRCodeRead={onQRCodeRead} />,
      button: (
        <Button secondary onClick={() => setCustomModalContent(null)}>
          Cancel
        </Button>
      ),
      fullScreen: true
    });
  };

  const handleIntent = (intent) => {
    setIntent(intent);
    setOpenNewTicketModal(true);
  };

  const {
    openEndSalesModal,
    EndSalesModalComponent, 
    handleEndSalesModalOpen
  } = useEndSalesController(
    event, 
    TicketResource, 
    ticketResourceParams
  );

  return (
    <>
      <TabPanels labels={["Tickets", "Stats"]}>
        <>
          <Header
            onCheckIn={handleOpenQRCodeScanner}
            onSellTickets={handleIntent}
            onSendTickets={handleIntent}
            openEndSalesModal={handleEndSalesModalOpen}
          />

          <DataSelectionTable
            records={objectState.filteredObjects}

            columns={ticketsColumns(
              handleOpenTicketDetails, imported_tickets_count
            )}

            searchLabel="Search by Ticket ref, Order or Member"
            onQueryChange={handleQueryChange}
            queryString={objectState.query}

            serverPaginationProps={{
              paginationServer: true,
              paginationTotalRows: objectState.totalCount,
              page: objectState.page,
              onChangePage: handlePageChange
            }}

            filter={
              <TicketFilter
                onChange={handleFilterChange}
              />
            }
          />
        </>

        <div>
          <Stats 
            TicketResource={TicketResource}
            ticketResourceParams={ticketResourceParams}
            stats={stats} 
          />
        </div>
      </TabPanels>

      {customModalContent && (
        <CustomModal
          handleClose={() => setCustomModalContent(null)}
          customModalContent={customModalContent}
        />
      )}

      {openNewTicketModal && (
        <NewTicketModal
          tickets={filteredTickets}
          onClose={() => setOpenNewTicketModal(false)}
          open={openNewTicketModal}
          done={fetchData}
          TicketResource={TicketResource}
          ticketResourceParams={ticketResourceParams}
          intent={intent}
        />
      )}

      {openEndSalesModal && 
        <EndSalesModalComponent />}
    </>
  );
};

TicketsList.propTypes = {
  TicketResource: PropTypes.object,
  ticketResourceParams: PropTypes.object,
  event: PropTypes.object,
  sessionId: PropTypes.string,
};

export default TicketsList;
