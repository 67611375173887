import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { useHistory } from 'react-router-dom';

import ContentResource from 'resources/Profile/ContentResource';

import { useError } from 'utils/useErrorController';

import { Chip, Menu, MenuItem } from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CloseIcon from '@mui/icons-material/Close';

const TagsFilter = ({ organizationSlug, organizationTags, activeTag }) => {

    const [selectedTag, setSelectedTag] = useState(activeTag)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const history = useHistory();

    const {handleError} = useError();
    const onTagSelect = async (tag) => {
      try {
        setAnchorEl(null);
        const isTagActive = selectedTag?.id === tag.id;

        const newUrl = isTagActive ? window.location.pathname : `?tag=${tag.name}`;
        history.replace(newUrl);

        setSelectedTag(isTagActive ? null : tag);
        await fetch(ContentResource.list(), { organization_slug: organizationSlug, page: 1, tag_id: isTagActive ? null : tag.id });

      } catch (error) {
        handleError(error)
      }
    };

    const handleClick = (event) => {
      event.preventDefault();
      setAnchorEl(event.currentTarget);
    };

    const handleClose = (event) => {
      event.preventDefault();
      setAnchorEl(null);
    };

  return (
    <div className='tags'>
      <Chip
        label={activeTag ? activeTag.name : 'Filter' }
        variant="outlined"
        onClick={(e) => activeTag ? onTagSelect(activeTag) : handleClick(e)}
        onDelete={(e) => activeTag ? onTagSelect(activeTag) : handleClick(e)}
        deleteIcon={activeTag ? <CloseIcon /> : <FilterAltIcon/>} />

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: "60vh",
          },
        }}
      >
        {organizationTags.map((tag) => (
          <MenuItem
            key={tag.id}
            value={tag.id}
            onClick={() => onTagSelect(tag)}
          >
            {tag.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

TagsFilter.propTypes = {
    organizationSlug: PropTypes.string,
    organizationTags: PropTypes.array,
    activeTag: PropTypes.number,
};

export default TagsFilter;
