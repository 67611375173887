import React, { useState } from "react";
import PropTypes from "prop-types";
import { useController } from "@rest-hooks/react";
import { useParams } from "react-router-dom";

import CheckinEventResource from "resources/checkin/EventResource";
import OrganizationEventResource from "resources/organization/EventResource";

import Content from "./Content";
import ButtonGroup from "./ButtonGroup";
import { handleValidation } from "./handleValidation";

import { useError } from "utils/useErrorController";
import { useToast } from "utils/context/ToastContext";
import customToast from "utils/customToast";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const NewTicketModal = ({ open, onClose, tickets, done, TicketResource, ticketResourceParams, intent="send" }) => {

  const [email, setEmail] = useState("");
  const [ticket, setTicket] = useState(tickets?.[0]);
  const [amount, setAmount] = useState(1);
  const [checkedInStatus, setCheckedInStatus] = useState(intent === "sell");

  const { handleError, setLoading, loading } = useError();
  const { setOpen, setMessage, setSeverity } = useToast();
  const { fetch } = useController();
  const { organizationId, eventId, eventUuid } = useParams();

  const EventResource = organizationId ? OrganizationEventResource : CheckinEventResource;
  
  const successMessage = intent === 'sell' 
        ? `${amount} Ticket${amount > 1 ? 's' : ''} Sold` 
        : `${amount} Ticket${amount > 1 ? 's' : ''} Sent`;

  const handleNewTicketSubmit = async () => {
    try {
      setLoading(true);
      handleValidation(email, ticket.id, intent);
      
      await fetch(
        TicketResource.create(),
        ticketResourceParams,
        {
          email: email,
          ticket_id: ticket.id,
          amount: amount,
          intent: intent,
          checked_in: checkedInStatus
        }
      );

      // fetch event details to refresh tickets list and available amounts
      await fetch(EventResource.detail(), { organization_id: organizationId, id: eventId || eventUuid });

      done();
      customToast("success", successMessage, setOpen, setSeverity, setMessage);
      handleClose();
      setLoading(false);
    } catch (error) {
      setLoading(false);
      handleError(error);
    }
  };

  const handleClose = () => {
    setEmail("");
    setTicket(tickets?.[0]);
    onClose();
  };

  return (
    <Dialog open={open} fullWidth={true} maxWidth="sm" className="new-ticket-modal" >
      <DialogTitle>
        {intent === 'sell' ? 'Register Cash Sale' : 'Send new Ticket'}
        <IconButton
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 12,
            color: "black",
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent dividers>
        <Content
          onEmailChange={setEmail}
          tickets={tickets}
          onTicketSelect={setTicket}
          ticket={ticket}
          amount={amount}
          onAmountChange={setAmount}
          intent={intent}
          checkedInStatus={checkedInStatus}
          onCheckedInStatusChange={setCheckedInStatus}
        />
      </DialogContent>
      <DialogActions sx={{ display: "flex" }}>
        <ButtonGroup
          handleClose={handleClose}
          handleNewTicketSubmit={handleNewTicketSubmit}
          loading={loading}
          intent={intent}
          disabled={tickets.length === 0}
        />
      </DialogActions>
    </Dialog>
  );
};

NewTicketModal.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
  tickets: PropTypes.array,
  done: PropTypes.func,
  TicketResource: PropTypes.object,
  ticketResourceParams: PropTypes.object,
  intent: PropTypes.string
};

export default NewTicketModal;
