import LocalPoliceIcon from '@mui/icons-material/LocalPolice';
import SettingsIcon from '@mui/icons-material/Settings';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import ExtensionIcon from '@mui/icons-material/Extension';
import RedeemTwoToneIcon from '@mui/icons-material/RedeemTwoTone';
import EventIcon from '@mui/icons-material/Event';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import PeopleIcon from '@mui/icons-material/People';
import WidgetsIcon from '@mui/icons-material/Widgets';
import ArticleIcon from '@mui/icons-material/Article';
// import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';

import { t } from 'i18n/index';

const studentNavLinks = [
  { id: "memberships", text: t('literal:memberships'), Icon: LocalPoliceIcon, link: '/profile' },
  { id: "tickets", text: t('literal:tickets'), Icon: LocalActivityIcon, link: '/tickets' },
  { id: "orders", text: t('literal:billingHistory'), Icon: ShoppingBasketIcon, link: '/orders' },
  { id: "settings", text: t('literal:preferences'), Icon: SettingsIcon, link: '/settings/details' },
];

// export const getNavLinks = (context, organizationId) => {
  export const getNavLinks = (context) => {
  if (context === "teacher") {
    return getTeacherNavLinks();
  } else {
    return studentNavLinks;
 }
};

// export const getTeacherNavLinks = (organizationId) => {
  export const getTeacherNavLinks = () => {
  const navLinks =
  [
    { id: 'settings', text: 'Club Profile', Icon: SettingsIcon, link: 'settings/details' },
    { id: 'elements', text: 'Link-In-Bio', Icon: ExtensionIcon, link: 'elements' },
    { id: 'events', text: 'Events', Icon: EventIcon, link: 'events' },
    { id: 'content', text: 'Newsletter', Icon: ArticleIcon, link: 'content' },
    { id: 'perks', text: 'Store', Icon: RedeemTwoToneIcon, link: 'perks' },
    { id: 'memberships', text: 'Memberships', Icon: ConfirmationNumberIcon, link: `memberships` },
    { id: 'community', text: 'Community', Icon: PeopleIcon, link: 'community' },
    { id: 'analytics', text: 'Reports & Analytics', Icon: WidgetsIcon, link: 'analytics/visits' },
    // { id: 'collectibles', text: 'Collectibles', Icon: LocalPoliceIcon, link: collectiblesLink },
  ]

  // const {status} = useSuspense(OnboardingResource.detail(), {organization_id: organizationId});
  // if (status === "started") {
  //   navLinks.unshift({ id: 'onboarding', text: 'Get Started', Icon: RocketLaunchIcon, link: 'start' });
  // }

  return navLinks;
} ;
