import React     from 'react';
import PropTypes from 'prop-types';

import Layout        from 'components/layouts/index'
import TabContainers from 'components/TabContainers';

import RewardInstancesTabContent from './RewardInstancesTabContent';
import OfferInstancesTabContent  from './OfferInstancesTabContent';
import OrdersTabContent          from './OrdersTabContent';
import NewslettersTabContent     from './NewslettersTabContent';
import StatsTabContent           from './StatsTabContent';
import TicketsTabContent         from './TicketsTabContent';
import StripePayoutsTabContent   from './StripePayoutsTabContent';

const pageInfo = {
  name: "Reports",
  description: "Track your club growth",
};

const Analytics = ({ match }) => {

  const settings = [
    { label: 'Visits',            segment: 'visits',      component: StatsTabContent },
    { label: 'Newsletters',       segment: 'newsletters', component: NewslettersTabContent },
    { label: 'Sold Tickets',      segment: 'tickets',     component: TicketsTabContent },
    { label: 'Sold Products',     segment: 'products',    component: OfferInstancesTabContent },
    { label: 'Membership Passes', segment: 'passes',      component: RewardInstancesTabContent },
    { label: 'Orders',            segment: 'orders',      component: OrdersTabContent },
    { label: 'Payouts',           segment: 'payouts',     component: StripePayoutsTabContent },
  ];

  return (
    <Layout context='teacher'
            activeMenuItem='analytics'
            pageInfo={pageInfo}>

      <TabContainers 
          path={match.path}
          containers={settings} />

    </Layout>
)};

Analytics.propTypes = {
  match: PropTypes.object
};

export default Analytics;
