import React, { useEffect} from 'react';
import { useController, useSuspense } from '@rest-hooks/react';
import { useParams } from 'react-router-dom';

import OrderResource from 'resources/organization/OrderResource';
import OrderDownloadResource from 'resources/organization/OrderDownloadResource';

import Stats from './Stats';

import DataSelectionTable from 'components/DataTable/DataSelectionTable';
import WithPermission from 'components/WithPermission';
import SalesFallback from 'components/fallbacks/Sales';

import Filter from 'ui/Filter';

import { downloadCSV } from 'utils/downloadFile';
import { ordersColumns } from 'utils/tables/columns/ordersColumns';
import usePaginationAndFilteringController from 'utils/usePaginationAndFilteringController';
import { useError } from 'utils/useErrorController';

const OrdersTabContent = () => {

  const { handleError } = useError();
  const params = useParams();
  const organizationId = parseInt(params.organizationId);

  const completedOrders = useSuspense(OrderResource.list(), {organization_id: organizationId, status: "completed"});
  const numberOfCompletedOrders = completedOrders.length;
  const totalAmountEarned = Math.floor(completedOrders.reduce((total, order) => total + order.total_price, 0) / 100);
  const currency = completedOrders.length > 0 ? completedOrders[0].currency : 'USD';

  const {
    objectState,
    fetchData,
    handlePageChange,
    handleFilterChange,
  } = usePaginationAndFilteringController({
      fetchDataResource: OrderResource,
      fetchDataParams: { organization_id: organizationId },
  })

  useEffect(() => {
    fetchData(1, {status: "completed"});
  }, []);

  const {fetch} = useController();

  const handleDownload = async () => {
    try {
      const response = await fetch(OrderDownloadResource.list(), {organization_id: organizationId, ...objectState.filteringParams});
      downloadCSV(response, 'orders.csv', 'text/csv');
    } catch (error) {
      handleError(error.message);
    }
  };

  return (
    <WithPermission permission={'hasSales'} fallback={<SalesFallback />}>

      <Stats stat1={ { counter: numberOfCompletedOrders } } stat2={ { counter: totalAmountEarned } } currency ={currency} />
      <div style={{ flexGrow: 1 }}>
          <DataSelectionTable records={objectState.filteredObjects}
                              columns={ordersColumns}
                              onClick={handleDownload}
                              serverPaginationProps={{
                                paginationServer: true,
                                paginationTotalRows: objectState.totalCount,
                                page: objectState.page,
                                onChangePage: handlePageChange
                              }}
                              filter={
                                <Filter
                                  onChange={handleFilterChange}
                                  label="status"
                                  table={'orders'}
                                />
                              }
          />
      </div>

    </WithPermission>
)};

export default OrdersTabContent;
