import React from 'react';
import PropTypes from 'prop-types';

import MarkdownEditor from 'components/MarkdownEditor';
import SessionSelector from 'components/SessionSelector';

import FormSection from 'ui/FormSection';
import Alert from 'ui/Alert';

import { DialogContent, TextField } from '@mui/material';

const Content = ({object, onObjectChange, event, onSessionSelect}) => {

  const handleChange = ({target: {name, value}}) => {
    let newSubscription = { ...object, [name]: value };
    onObjectChange(newSubscription);
  };

  const handleSessionChange = ({target: {value}}) => {
    onSessionSelect(value);
  };

  return (
    <DialogContent>
      <FormSection slim>

        <TextField
            fullWidth
            color='warning'
            name={'subject'}
            size={'small'}
            label={'Email Subject *'}
            value={ object.subject}
            onChange={handleChange}
          />

        {<MarkdownEditor 
            object={object}
            setObject={onObjectChange}
            attribute={"content"}
            placeholder={"Email Body *"}
            height={"200px"} />}

        { event && event.sessions.length > 0 && 
          <>
            <Alert primaryText={'Select a session below to send this email to its guests only or leave the selector blank to send it to all guests.'} slim />
            <SessionSelector options={event.sessions} onChange={handleSessionChange} slim={true} showTitle={false}/>
          </>}
              
      </FormSection>
      
    </DialogContent>
  );
};

Content.propTypes = {
  object: PropTypes.object,
  onObjectChange: PropTypes.func,
  event: PropTypes.object,
  onSessionSelect: PropTypes.func
};

export default Content;