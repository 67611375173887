import React from "react";
import PropTypes from "prop-types";

import CheckboxElement from "./Checkbox";

const TicketSelector = ({availableTickets, ticketIds, onTicketCheckboxChange}) => {

  const renderTicketSelection = () => {
    // if no sessions and flat list of tickets
    if (!availableTickets[0]?.sessionTitle) {
      return availableTickets.map((ticket) => (
        <CheckboxElement
          key={ticket.id}
          ticket={ticket}
          ticketIds={ticketIds}
          onTicketCheckboxChange={onTicketCheckboxChange}
        />
      ));
    }

    // if tickets are grouped by sessions
    return availableTickets.map(({ sessionTitle, tickets }) => (
      <div key={sessionTitle} style={{ marginBottom: "1rem" }}>
        {sessionTitle && <strong>Session: {sessionTitle}</strong>}
        {tickets.map((ticket) => (
          <CheckboxElement
            key={ticket.id}
            ticket={ticket}
            ticketIds={ticketIds}
            onTicketCheckboxChange={onTicketCheckboxChange}
          />
        ))}
      </div>
    ));
  };

  return (
    <>
      <p>Please select the tickets you want to end the sale of:</p>
      {renderTicketSelection()}
    </>
  );
};

TicketSelector.propTypes = {
  availableTickets: PropTypes.array,
  ticketIds: PropTypes.array,
  onTicketCheckboxChange: PropTypes.func,
};

export default TicketSelector;
