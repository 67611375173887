import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';

import Button from 'ui/Button';

import ButtonGroup from '@mui/material/ButtonGroup';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';

import './styles.css';

const SplitButton = ({ MainButton, options, disabled, highlighted, secondary }) => {
  const [open, setOpen] = useState(false);
  const [selectedIndex, setSelectedIndex] = useState(1);

  const anchorRef = useRef(null);

  const handleMenuItemClick = (event, index, onClick) => {
    setSelectedIndex(index);
    setOpen(false);
    onClick();
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  return (
    <>
      <ButtonGroup
        variant="contained"
        ref={anchorRef}
        fullWidth
        className={'ui-split-button'}
      >
        <MainButton />
        <Button 
          icon={ArrowDropDownIcon}
          onClick={handleToggle}
          secondary={secondary}
          highlighted={highlighted}
          disabled={disabled}
          className={'menu-button'}
          >
        </Button>
      </ButtonGroup>
      <Popper
        sx={{ zIndex: 1000, width: anchorRef.current ? `${anchorRef.current.offsetWidth}px` : 'auto' }}
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom' ? 'right top' : 'right bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList id="split-button-menu" autoFocusItem sx={{ textAlign: 'center' }}>
                  {options.map((option, index) => (
                    <MenuItem
                      key={option}
                      selected={index === selectedIndex}
                      onClick={(event) => handleMenuItemClick(event, index, option.onClick)}
                      sx={{ justifyContent: 'center', gap: '8px' }}
                      disabled={option.disabled}
                    >
                      {option.icon}{option.label}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

SplitButton.propTypes = {
  MainButton: PropTypes.node,
  options: PropTypes.array,
  disabled: PropTypes.bool,
  highlighted: PropTypes.bool,
  secondary: PropTypes.bool,
};

export default SplitButton;