import React from 'react';
import PropTypes from 'prop-types';

import Button from 'ui/Button';

import PromoCodeSelector from './PromoCodeSelector';

import { emptyPromoCode } from "utils/offersFormHelper";

import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutline';
import NewPromoCode from './NewPromoCode';

export const PromoCodes = ({price, currency, new_promo_codes, existing_promo_codes, onChange, disabled}) => {

  const addNewPromoCode = (e) => {
    e.preventDefault();
    let data = new_promo_codes ? new_promo_codes : [];
    data.push({...emptyPromoCode});
    onChange({target: {name: "new_promo_codes", value: data }});
  };

  const showPromoCodeSelector = (new_promo_codes && new_promo_codes.length > 0) || (existing_promo_codes && existing_promo_codes.length > 0);
  const showNewPromoCodeInputFields = new_promo_codes && new_promo_codes.length > 0;

  return(
  <>
    {showPromoCodeSelector ?
      <PromoCodeSelector promo_codes={existing_promo_codes} onChange={onChange} disabled={disabled}/>
      : null
    }

    {showNewPromoCodeInputFields ?
      <NewPromoCode price={price} currency={currency} new_promo_codes={new_promo_codes} onChange={onChange} />
      : null
    }    

    <Button text small icon={AddCircleOutlineOutlinedIcon} onClick={addNewPromoCode} disabled={disabled}>Add Promo Code</Button>    
  </>
)};

PromoCodes.propTypes = {
  price: PropTypes.number,
  currency: PropTypes.string,
  new_promo_codes: PropTypes.array,
  existing_promo_codes: PropTypes.array,
  onChange: PropTypes.func,
  disabled: PropTypes.bool
};

export default PromoCodes;
