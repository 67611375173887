import React from 'react';
import PropTypes from 'prop-types';

import TableButton from 'components/TableButton';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const ActionButtons = ({promoCode, loading, organizationId, history, handleConfirmDeleteModal, handleCopy}) => {
    const actionButtonsData = [
        {
          label: 'Copy Code',
          icon: ContentCopyIcon,
          action: () => handleCopy(promoCode),
          disabled: loading,
        },
        {
          label: 'Edit',
          icon: EditIcon,
          action: () => history.push(`/organizations/${organizationId}/perks/promo-codes/${promoCode.id}/edit`),
          disabled: loading,
        },
        {
          label: 'Delete',
          icon: DeleteIcon,
          action: () => handleConfirmDeleteModal(promoCode),
          disabled: loading || promoCode.has_been_used,
        },
      ];

  return (
    <div>
        {actionButtonsData.map((data) => (
            <TableButton
                key={data.label}
                title={data.label}
                Icon={data.icon}
                action={data.action}
                disabled={data.disabled}
            />
        ))}
    </div>
  );
};

ActionButtons.propTypes = {
    promoCode: PropTypes.object,
    loading: PropTypes.bool,
    organizationId: PropTypes.number,
    history: PropTypes.object,
    handleConfirmDeleteModal: PropTypes.func,
    handleCopy: PropTypes.func,
};

export default ActionButtons;