import React from 'react';
import PropTypes from 'prop-types';

import { currencies } from "utils/pricingsConsts";

import { IconButton, InputAdornment, TextField } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { getNewNetDiscount } from 'utils/offersFormHelper';

const NewPromoCode = ({ price, currency, new_promo_codes, onChange }) => {

    const handleNewPromoCodeChange = (event, index) => {
      const { value, name } = event.target;
      
      const newValue = name === "percent_off" && value >= 100 ? 99 : value;
      const newNetDiscount = name === "percent_off" && value ? getNewNetDiscount(price, newValue) : "";

      let data = [...new_promo_codes];
      data[index][name] = newValue;
      data[index]["net_discount"] = newNetDiscount;
  
      onChange({target: {name: "new_promo_codes", value: data }});
    };

    const deleteNewPromoCode = (e, index) => {
      e.preventDefault();
      let data = [...new_promo_codes];
      data.splice(index, 1);
      onChange({target: {name: "new_promo_codes", value: data }});
    };
    
  return (
    <>
      {new_promo_codes.map((promo_code, index) => {
        return (
          <div className="row" key={`promo_code-${index}`} >
              <TextField
                  fullWidth
                  size='small'
                  name="code"
                  label="Add New Promo Code"
                  color="warning"
                  value={promo_code.code || ""}
                  onChange={event => handleNewPromoCodeChange(event, index)}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">
                                    <IconButton
                                      onClick={(e)=> deleteNewPromoCode(e, index)}
                                      onMouseDown={(e) => e.preventDefault()}
                                    >
                                      <CloseIcon />
                                    </IconButton>
                                  </InputAdornment>,
                  }} />

              <TextField
                fullWidth
                size='small'
                name="percent_off"
                label="Percentage Off"
                color="warning"
                value={promo_code.percent_off || ""}
                type="number"
                inputProps={{ inputMode: 'numeric' }}
                onChange={event => handleNewPromoCodeChange(event, index)}
                InputProps={{
                  endAdornment: <>
                    {
                      promo_code.net_discount 
                        ? <InputAdornment position="end">{promo_code.net_discount} {currencies[currency].symbol}</InputAdornment> 
                        : null
                    }
                  </>,
                }}
                />
              
              
              
          </div>
        )
      })}
    </>
  );
};

NewPromoCode.propTypes = {
  price: PropTypes.number,
  currency: PropTypes.string,
  new_promo_codes: PropTypes.array,
  onChange: PropTypes.func,
};

export default NewPromoCode;