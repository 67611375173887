import React, { useRef } from "react";
import PropTypes from "prop-types";

import Button from "ui/Button";
import Subtitle from "ui/text/Subtitle";

import UploadFileIcon from "@mui/icons-material/UploadFile";

const FileUploader = ({ data, onDataChange }) => {
  const hiddenFileInput = useRef(null);

  const handleFileUpload = async (event) => {
    if (!event.target.files) return;

    const file = event.target.files[0];

    const newData = { ...data, file: file };
    onDataChange(newData);
  };

  const { file } = data;

  return (
    <>
      <Subtitle small>Step 3: Upload a CSV file</Subtitle>
      <Button
        fullwidth
        icon={UploadFileIcon}
        disabled={!data.event_id || !data.platform}
        onClick={() => hiddenFileInput.current.click()}
      >
        {file ? `Uploaded ${file.name}` : "Upload spreadsheet"}
        <input
          type="file"
          ref={hiddenFileInput}
          hidden
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          onChange={(e) => handleFileUpload(e)}
        />
      </Button>
    </>
  );
};

FileUploader.propTypes = {
  data: PropTypes.object,
  onDataChange: PropTypes.func,
};

export default FileUploader;
