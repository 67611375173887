import { useState } from 'react';
import { useController } from '@rest-hooks/react';
import { useParams } from 'react-router-dom';

import ProductResource from 'resources/Profile/ProductResource';
import MembershipResource from 'resources/Profile/MembershipResource';
import TicketResource from 'resources/Profile/TicketResource';
import EventResource from 'resources/Profile/EventResource';

import { useError } from './useErrorController';
import { offerCategory } from './offersFormHelper';
import { checkLoginStatus } from './checkLoginStatus';

import { t } from "i18n/index";

const defaultModalState = {
  authModal: false,
  redeemModal: false,
  checkoutModal: false,
};

export const usePreActionController = (organizationSlug, activeTag=null, eventSlug=null) => {
  
  const [currentAction, setCurrentAction] = useState(null);
  const [modalsState, setModalsState] = useState(defaultModalState)

  const { fetch } = useController();
  const { handleError } = useError();
  const { ticketSlug } = useParams();

  const handleAction = (product, redemptionMethod) => {
    setCurrentAction({ product, redemptionMethod });

    const isLoggedIn = checkLoginStatus();
    if (isLoggedIn) {
      if (redemptionMethod === 'points') {
        // check if enough points to redirect to memberships and tranform into subscriptions quicker (not to validate)
        setModalsState({ ...modalsState, redeemModal: true });
      } else {
        setModalsState({ ...modalsState, checkoutModal: true });
      }
    } else {
      setModalsState({ ...modalsState, authModal: true });
    }
  };

  const handleSuccessfulAuth = async () => {
    //update perks with redeemed_counts after auth
    let products
    const {id, category} = currentAction.product;
    if (category === offerCategory.ticket.value) {
      // the existance of ticketSlug determines if we are in a single event page with secret ticket or without
      // both TicketResource and EventResouce return an event
      // TicketResource returns an event with the secret ticket whereas EventResource returns an event with all tickets
      const event = ticketSlug
                      ? await fetch(TicketResource.detail(), {organization_slug: organizationSlug, event_slug: eventSlug, id: ticketSlug })
                      : await fetch(EventResource.detail(), {organization_slug: organizationSlug, id: eventSlug});
      products = event?.tickets;
    } else {
      products = await fetch(ProductResource.list(), {organization_slug: organizationSlug, tag_id: activeTag?.id});
    }

    // find relevant product to check if already redeemed max and use the variable to update the current_action.product
    const product = products.find(product => product.id === id);
    const alreadyRedeemedMax = product.redeem_limit && product.redeemed_count >= product.redeem_limit;

    //refresh memberships list
    await fetch(MembershipResource.list(), {organization_slug: organizationSlug});

    if (alreadyRedeemedMax) {
      handleError(t('errors:redeemedMax'));
      const newModalState = { ...modalsState, authModal: false};
      setModalsState(newModalState);
    } else {
      //updating the current_action.product results in updated discount values in the order summary
      handleProductChange(product);
      const newOpenModal = currentAction.redemptionMethod === 'points' ? 'redeemModal' : 'checkoutModal';
      const newModalState = { ...modalsState, [newOpenModal]: true, authModal: false};
      setModalsState(newModalState);
    }
  };

  const handleClose = () => {
    setCurrentAction(null);
    setModalsState(defaultModalState);
  };

  const handleProductChange = (product) => {
    const newAction = { ...currentAction, product };
    setCurrentAction(newAction);
  };

  return {  
    currentAction,
    modalsState,
    onProductChange: handleProductChange,
    onAction: handleAction,  
    onSuccessfulAuth: handleSuccessfulAuth,
    onClose: handleClose,
  };
};
