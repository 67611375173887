import React, { useState } from "react";
import PropTypes from "prop-types";

import Button from "ui/Button";

import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import SellIcon from '@mui/icons-material/Sell'; 
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import StorefrontRoundedIcon from '@mui/icons-material/StorefrontRounded';
import EventIcon from '@mui/icons-material/Event';
import { Tooltip } from "@mui/material";

import moment from "moment";

const generateInitialListItems = (ticket) => [
  {
    label: "Status",
    value: ticket.redeem_state === "completed" ? "Checked In" : "Not Checked In",
    icon: ticket.redeem_state === "completed" ? CheckCircleOutlineIcon : CancelOutlinedIcon,
    iconColor: ticket.redeem_state === "completed" ? "success" : "error",
  },
  { label: "Name", value: ticket.user_name, icon: PersonIcon },
  { label: "Email", value: ticket.user_email, icon: EmailIcon },
  { label: "Platform", value: ticket.platform, icon: StorefrontRoundedIcon },
];

const generateDetailedListItems = (ticket) => [
  { label: "Order Id", value: ticket.order_id || "N/A", icon: SellIcon },
  { label: "Ticket Reference", value: ticket.uuid, icon: ConfirmationNumberIcon },
  { label: "Date of Purchase", value: moment(ticket.created_at).format("lll"), icon: EventIcon },
];

const CheckInModalContent = ({ ticket }) => {
  const [detailsVisible, setDetailsVisible] = useState(false);

  const listItems = detailsVisible
    ? [...generateInitialListItems(ticket), ...generateDetailedListItems(ticket)]
    : generateInitialListItems(ticket);

  return (
    <>
      <div className="checkin-modal-content">
        {listItems.map((item) => (
          <div key={item.label}>
            <Tooltip title={item.label}>
              <item.icon color={item.iconColor || "warning"}/>
            </Tooltip>
            <p key={item.label}>
              {item.value}
            </p>
          </div>
        ))}
      </div>

      {!detailsVisible && (
        <Button iconColor="warning" smallPadded text onClick={() => setDetailsVisible(true)}>View More Details →</Button>
      )}
    </>
  );
};

CheckInModalContent.propTypes = {
  ticket: PropTypes.object.isRequired,
};

export default CheckInModalContent;
