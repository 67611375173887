import React from 'react';
import PropTypes from 'prop-types';

import SaveIcon from '@mui/icons-material/Save';

import Button from "ui/Button";
import Status from './Status';

const Footer = ({id, loading, onSubmit, status, onChange}) => {
  return (
    <div className={`new-form-field-footer`}>
        <Button minwidth loading={loading} icon={SaveIcon} onClick={onSubmit}>
            {!id ? "Create" : "Save"}
        </Button>
        <Status status={status} onChange={onChange}/>
    </div>
  );
};

Footer.propTypes = {
  id: PropTypes.number,
  loading: PropTypes.bool,
  onSubmit: PropTypes.func,
  status: PropTypes.string,
  onChange: PropTypes.func
};

export default Footer;