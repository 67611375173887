import React from 'react';
import PropTypes from 'prop-types';

import { Checkbox, FormControlLabel } from "@mui/material";

const UniversalTimeSelector = ({event, onEventChange, setUnsavedChanges}) => {

    const {repeat_days, start_time, universal_time = true} = event;

    const handleCheckBox = ({target: {checked}}) => {
        setUnsavedChanges(true);

        let newEvent
        if (checked) { 
            
            // checkbox is checked: repeat days should share the same start time
            // all repeat days must be updated to have the first repeat day start time
            // event start time should be updated to have the first repeat day start time

            const firstDayKey = repeat_days.length > 0 && Object.keys(repeat_days[0])[0];
            const firstDayStartTime = repeat_days.length > 0 && repeat_days[0][firstDayKey]?.isValid() ? repeat_days[0][firstDayKey] : null;

            const newRepeatDays = repeat_days.length === 0 
                ? repeat_days
                : repeat_days.map(day => {
                    const dayKey = Object.keys(day)[0];
                    return { [dayKey]: firstDayStartTime };
            });


            newEvent = { ...event, universal_time: true, repeat_days: newRepeatDays, start_time: firstDayStartTime};
        } else {

            // checkbox is unchecked: repeat days may have different start times
            // initially all repeat days should take the event start time
            // event start time doesnt require any change if is valid but we will reassign it in case it is for better readability

            const startTime = start_time && start_time.isValid() ? start_time : null

            const newRepeatDays = repeat_days.length === 0 
                ? repeat_days
                : repeat_days.map(day => {
                    const dayKey = Object.keys(day)[0];
                    return { [dayKey]: startTime };
            });

            newEvent = { ...event, universal_time: false, repeat_days: newRepeatDays, start_time: startTime};
        }

        onEventChange(newEvent);
    };

  return (
    <FormControlLabel
        control={
            <Checkbox
                size="small"
                checked={universal_time}
                onChange={handleCheckBox}
                color="primary"
            />
        }
        style={{ margin: "0px" }}
        label={"Sessions have same start time"}
    />
  );
};

UniversalTimeSelector.propTypes = {
    event: PropTypes.object,
    onEventChange: PropTypes.func,
    setUnsavedChanges: PropTypes.func,
};

export default UniversalTimeSelector;