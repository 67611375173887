import React from 'react';
import PropTypes from 'prop-types';

import Button from 'ui/Button';
import SplitButton from 'ui/SplitButton';

import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import StorefrontIcon from '@mui/icons-material/Storefront';
import EventBusyIcon from '@mui/icons-material/EventBusy';

const Header = ({ onCheckIn, onSellTickets, onSendTickets, openEndSalesModal }) => {

  const buttonOptions = [
    {label: "Send Ticket via Email", onClick: () => { onSendTickets("send")}, icon: <ForwardToInboxIcon />},
    {label: "End Ticket Sales", onClick: () => openEndSalesModal(), icon: <EventBusyIcon />},
  ];

  const MainButton = () =>
    <Button icon={StorefrontIcon} secondary fullwidth onClick={() => {onSellTickets("sell")}} >
        Register Cash Sale
    </Button>;

  return (
    <div className="tickets-tab-header">
      <Button highlighted onClick={onCheckIn} fullwidth icon={QrCodeScannerIcon}>
        Check In
      </Button>
      <SplitButton MainButton={MainButton} options={buttonOptions} secondary fullwidth={false}/>
    </div>
  );
};

Header.propTypes = {
  onCheckIn: PropTypes.func,
  onSellTickets: PropTypes.func,
  onSendTickets: PropTypes.func,
  openEndSalesModal: PropTypes.func
};

export default Header;
