import React     from 'react';
import PropTypes from 'prop-types';

import Layout             from 'components/layouts/index';
import TabContainers      from 'components/TabContainers';
import UpdateOrganization from '../Organization/Wizzard/update';
import PreferencesForm    from './PreferencesForm';
import MembersForm        from './MembersForm';
import Stripe             from './Stripe';
import Integrations       from './Integrations';
import NewslettersForm    from './NewslettersForm';

import isMobileDevice from 'utils/mobileAndTabletCheck';

const pageInfo = {
  name: "Club Profile",
  description: "Manage club basic settings",
};

const settings = [
  { label: 'Details',        segment: 'details',       component: UpdateOrganization },
  { label: 'Emails',         segment: 'email',         component: NewslettersForm },
  { label: 'Payments',       segment: 'payments',      component: Stripe },
];

if (!isMobileDevice()) {
  settings.splice(1, 0, { label: 'Preferences', segment: 'preferences',   component: PreferencesForm });
  settings.push(
    { label: 'Collaborators',  segment: 'collaborators', component: MembersForm },
    { label: 'Integrations',   segment: 'integrations',  component: Integrations },
  );
}

const Settings = ({ match }) => {

  return (
    <Layout context='teacher'
            activeMenuItem='settings'
            pageInfo={pageInfo}
            >

      <TabContainers path={match.path}
                     containers={settings}
                     />
    </Layout>
)};

Settings.propTypes = {
  match: PropTypes.object
};

export default Settings;
