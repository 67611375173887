import React from "react";
import PropTypes from "prop-types";

import StatCard from "components/StatCard";
import { formatPrice } from "utils/rewards";

const Stats = ({ events }) => {
  const event = events?.[0];

  const stat1 = event ? formatPrice(event.total_revenue, event.currency) : 0;

  const stat2 = event ? event.total_tickets_count : 0;

  const stat3 = event ? event.total_count : 0;

  return ( 
    <div className="event-stats-grid grid custom-margin">
      <StatCard
        type="number"
        color="rgb(54, 162, 235)"
        highlight={stat1}
        title={`Total Revenue`}
      />
      <StatCard
        type="number"
        color="rgb(255, 205, 86)"
        highlight={stat2}
        title="Sold Tickets Count"
      />
      <StatCard
        type="number"
        color="rgb(255, 99, 132)"
        highlight={stat3}
        title="Events Count"
      />
    </div>
  );
};

Stats.propTypes = {
  events: PropTypes.array,
};

export default Stats;
