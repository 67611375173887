import React from 'react';
import { useSuspense } from '@rest-hooks/react';
import { useParams } from 'react-router-dom';

import EventsList from './EventsList';

import Layout from 'components/layouts/index'
import TabPanels from 'components/TabPanels';
import AlertCta from 'components/AlertCta';

import OrganizationResource from 'resources/organization/OrganizationResource';

const pageInfo = {
  name: 'Events',
  description: 'Create and organize your organization events here',
  action: true
};

const Events = () => {

  const params = useParams();
  const organizationId = params.organizationId;

  const {slug, logo_url} = useSuspense(OrganizationResource.detail(), { id: organizationId });

  return (
    <Layout context='teacher'
            activeMenuItem='events'
            pageInfo={pageInfo}
            cta={
              <AlertCta
                primaryText={"Your next event: "}
                externalUrl={`${window.location.origin}/${slug}/events/next`}
                qrCodeTitle={"Your next event"}
                qrCodeimage={logo_url}
              />
            }>
      <TabPanels labels={["Upcoming Events", "Past Events"]}>      
        <EventsList upcoming={true}/>
        <EventsList past={true} />
      </TabPanels>
    </Layout>
  )
};

export default Events;
