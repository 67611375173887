import React from "react";
import PropTypes from "prop-types";

import Button from "ui/Button";

const ButtonGroup = ({handleClose, handleNewTicketSubmit, loading, intent, disabled}) => {
  return (
    <>
        <Button secondary onClick={handleClose} fullwidth loading={loading}>
          Cancel
        </Button>
        <Button
          highlighted
          fullwidth
          onClick={handleNewTicketSubmit}
          loading={loading}
          disabled={disabled}
        >
          {intent === "sell" ? "Complete Sale" : "Send Now"}
        </Button>
    </>
)}

ButtonGroup.propTypes = {
  handleClose: PropTypes.func,
  handleNewTicketSubmit: PropTypes.func,
  loading: PropTypes.bool,
  intent: PropTypes.string,
  disabled: PropTypes.bool
};

export default ButtonGroup;