import React from "react";
import PropTypes from "prop-types";

import Button from "ui/Button";

import DialogActions from "@mui/material/DialogActions";

const Actions = ({onClose, onEndSaleRequest, ticketIds}) => {
  
  return (
    <DialogActions sx={{ display: "flex" }}>
      <Button fullwidth secondary onClick={onClose}>
        Cancel
      </Button>
      <Button
        fullwidth
        highlighted
        onClick={onEndSaleRequest}
        disabled={ticketIds.length === 0}
      >
        End Now
      </Button>
    </DialogActions>
  );
};

Actions.propTypes = {
  onClose: PropTypes.func,
  onEndSaleRequest: PropTypes.func,
  ticketIds: PropTypes.array
};

export default Actions;
