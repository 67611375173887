import React from "react";
import mbwayApp from "assets/images/mbwayApp.png";

import { t } from "i18n/index";

const EupagoPending = () => {

  return (
    <div className="payment-method-mbway-app" >
      <h2>1) {t('profile:memberships:claimModal:processingEupago_part1')}</h2>
      <h2>2) {t('profile:memberships:claimModal:processingEupago_part2')}</h2>
      <img src={mbwayApp} className='image' />
    </div>
  );
};

export default EupagoPending;
