import React, { useState } from "react";
import PropTypes from "prop-types";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import Actions from "./Actions";
import Content from "./Content";
import { useController } from "@rest-hooks/react";
import TicketResource from "resources/admin/TicketResource";
import { useError } from "utils/useErrorController";
import customToast from "utils/customToast";
import { useToast } from "utils/context/ToastContext";

const emptyData = {
  event_id: "",
  platform: "",
  file: null,
};

const ImportTicketsModal = ({ open, onClose }) => {
  const [data, setData] = useState(emptyData);
  const {fetch} = useController();
  const {handleError} = useError();
  const { setOpen, setMessage, setSeverity } = useToast();

  const handleSubmit = async () => {
    const formData = new FormData();
    Object.entries(data).forEach(([name, value]) => formData.append(name, value));

    try {
      const {counter, errors} = await fetch(TicketResource.create(), formData);
      if (errors?.length > 0) {
        errors.unshift(`✅ ${counter} ticket${counter === 1 ? '' : 's'} imported.`);
        handleError(errors, true);
      } else {
        const successMessage = `${counter} ticket${counter === 1 ? '' : 's'} imported`;
        customToast( "success", successMessage, setOpen, setSeverity, setMessage );
        onClose();
      }
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <Dialog open={open} fullWidth maxWidth="md" onClose={onClose}>
      <DialogTitle>Import tickets</DialogTitle>

      <Content data={data} onDataChange={setData} />

      <Actions data={data} handleSubmit={handleSubmit} onClose={onClose} />
    </Dialog>
  );
};

ImportTicketsModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default ImportTicketsModal;
