export const offerType = {
    internal: 'InternalOffer',
    external: 'ExternalOffer',
};

export const offerCategory = {
    merch: { value: 'merch', label: 'Merchandise' },
    ticket: { value: 'ticket', label: 'Ticket' }, 
    perk: { value: 'perk', label: 'Perk' },
};

export const emptyOfferDefinition = {
    title: '',
    description: '',
    extended_description: '',
    tag_ids: [],
    file: null,
    filename: '',
    price: '',
    currency: "USD",
    coupons: [],
    points: '',
    supply_limit: '',
    redeem_limit: '',
    end_date: '',
    show_end_date: false,
    show_supply: false,
    status: "active",
    notification_list: "",
    slug: '',
    new_promo_codes: [],
    existing_promo_codes: [],
};

export const emptyCoupon = {
    tier_title: '',
    tier_id: '',
    percentage_off: '',
};

export const emptyPromoCode = {
    code: '',
    percent_off: '',
};

export const emtpyInternalOffer = {
    reward_ids: ['public'],    
};

export const emtpyTextOffer = {
    content_text: '',
    content_text_link: ''
};

export const defaultOffer = {
    ...emptyOfferDefinition,
    ...emtpyInternalOffer,
    ...emtpyTextOffer,
};

export const visibilityOptions = [
    { value: 'always', label: 'Always visible, even after selling out'},
    { value: 'available', label: 'Only visible when in stock / within available dates'},
    { value: 'secret', label: 'Hidden from listings (Secret)'}
];

export const getNewNetDiscount = (price, percent_off) => {
    if (price && percent_off) {
      return price * percent_off / 100;
    }
    return 0;
};
 
export const updateCoupons = (coupons, newUnitPrice) => {
    if (coupons) {
      return coupons.map((coupon) => ({ ...coupon, net_discount: getNewNetDiscount(newUnitPrice, coupon.percent_off) }));
    }
    return [];
};

export const updatePromoCodes = (promoCodes, newUnitPrice) => {
    if (promoCodes) {
      return promoCodes.map((promoCode) => ({ ...promoCode, net_discount: getNewNetDiscount(newUnitPrice, promoCode.percent_off) }));
    }
    return [];
};