import React from "react";
import PropTypes from "prop-types";

import TicketSelector from "./TicketSelector";

import FormSection from "ui/FormSection";

import DialogContent from "@mui/material/DialogContent";

const Content = ({ availableTickets, ticketIds, onTicketCheckboxChange }) => {
  const getModalContent = () => {
    if (availableTickets.length === 0) {
      return (
        <FormSection slim>
          <p>No tickets available</p>
        </FormSection>
      );
    }

    // single ticket in single event
    if (availableTickets.length === 1 && !availableTickets[0].sessionTitle) {
      return (
        <FormSection slim>
          <p>Are you sure you want to end the sale of the ticket <strong>{availableTickets[0].identifier}</strong>?</p>
        </FormSection>
      );
    }

    // single ticket in event with sessions
    if (availableTickets.length === 1 && availableTickets[0].sessionTitle && availableTickets[0].tickets.length === 1) {
      return (
        <FormSection slim>
          <p>Are you sure you want to end the sale of the ticket <strong>{availableTickets[0].tickets[0].identifier}</strong> for the session happening on ${availableTickets[0].sessionTitle}?</p>
        </FormSection>
      );
    }

    // multiple tickets
    return (
      <TicketSelector
        availableTickets={availableTickets}
        ticketIds={ticketIds}
        onTicketCheckboxChange={onTicketCheckboxChange}
      />
    );
  };

  return (
    <DialogContent dividers>
      {getModalContent()}
    </DialogContent>
  );
};

Content.propTypes = {
  availableTickets: PropTypes.array,
  ticketIds: PropTypes.array,
  onTicketCheckboxChange: PropTypes.func
};

export default Content;
