import React from 'react';
import PropTypes from 'prop-types';

import Button from 'ui/Button';

import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

import './styles.css';

const Alert = ({primaryText, primaryButtonIcon=null, primaryButtonAction=null, primaryButtonLabel=null, secondaryButtonIcon=null, secondaryButtonAction=null, secondaryButtonLabel=null, slim=false}) => {
  
  return (
    <div className={slim ? 'ui-alert slim' : 'ui-alert'}>
      <div className="content">
        <InfoOutlinedIcon color="info"/>
        <div>
          <p>{primaryText}</p>
        </div>
      </div>
      {primaryButtonAction &&
        <div className="actions">
          <Button icon={primaryButtonIcon} onClick={primaryButtonAction} smallPadded>
            {primaryButtonLabel}
          </Button>
          {secondaryButtonAction && 
            <Button icon={secondaryButtonIcon} onClick={secondaryButtonAction} smallPadded>
              {secondaryButtonLabel}
            </Button>}
        </div>}
    </div>
  );
};

Alert.propTypes = { 
  primaryText: PropTypes.string,
  primaryButtonIcon: PropTypes.element,
  primaryButtonAction: PropTypes.func,
  primaryButtonLabel: PropTypes.string,
  secondaryButtonIcon: PropTypes.element,
  secondaryButtonAction: PropTypes.func,
  secondaryButtonLabel: PropTypes.string,
  slim: PropTypes.bool
};

export default Alert;