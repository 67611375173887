import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";

import OrganizationResource from "resources/organization/SearchableFilterResource";

import { useErrorController } from "utils/useErrorController";

import CircularProgress from "@mui/material/CircularProgress";
import { Autocomplete, TextField } from "@mui/material";

import { debounce } from "lodash";
import { useController } from "@rest-hooks/react";
import { useParams } from "react-router-dom";

const PromoCodeSelector = ({ promo_codes, onChange, disabled }) => {
  const [options, setOptions] = useState([]);

  const { loading, setLoading, handleError } = useErrorController();
  const { fetch } = useController();
  const { organizationId } = useParams();

  const renderOption = (props, option) => (
    <li {...props}>{option.identifier}</li>
  );
  const renderInput = (params) => (
    <TextField
      {...params}
      label={"Associate Existing Promo Codes"}
      color="warning"
      InputProps={{
        ...params.InputProps,
        endAdornment: (
          <>
            {" "}
            {loading ? <CircularProgress color="inherit" size={20} /> : null}
            {params.InputProps.endAdornment}
          </>
        ),
      }}
    />
  );

  const debouncedSearch = useCallback(
    debounce(async (query) => {
      try {
        const response = await fetch(OrganizationResource.list(), {
          query: query,
          entity: "promo_code",
          organization_id: organizationId,
        });
        const filteredResponse = promo_codes
          ? response.filter(
              (item) =>
                !promo_codes.some((promo_code) => promo_code.id === item.id)
            )
          : response;
        setOptions(filteredResponse);
      } catch (error) {
        handleError(error);
      } finally {
        setLoading(false);
      }
    }, 1000),
    [fetch, promo_codes]
  );

  const handleOpen = () => {
    setLoading(true);
    debouncedSearch("");
  };

  const handleQueryChange = (event, newValue, reason) => {
    if (reason === "input") {
      setLoading(true);
      debouncedSearch(newValue);
    }
  };

  const handleSelection = (event, newValue) => {
    onChange({ target: { name: "existing_promo_codes", value: newValue } });
  };

  return (
    <Autocomplete
      size="small"
      value={promo_codes}
      selectOnFocus
      blurOnSelect
      clearOnBlur
      clearOnEscape
      handleHomeEndKeys
      openOnFocus
      loading={loading}
      options={options}
      onChange={handleSelection}
      onOpen={handleOpen}
      onInputChange={handleQueryChange}
      renderOption={renderOption}
      renderInput={renderInput}
      multiple={true}
      getOptionLabel={(option) => option.identifier}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      disabled={disabled}
    />
  );
};

PromoCodeSelector.propTypes = {
  promo_codes: PropTypes.array,
  onChange: PropTypes.func,
  disabled: PropTypes.bool
};

export default PromoCodeSelector;
