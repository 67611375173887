import React from 'react';
import PropTypes from 'prop-types';

import Subtitle from 'ui/text/Subtitle';
import Description from 'ui/text/Description';

import ButtonGroup from 'components/OfferCardButtonGroup';
import OfferCardSettings from 'components/OfferCardSettings';

const TicketCard = ({ ticket, onAction }) => {

    const {title, description, show_end_date, show_supply, end_date, issued_counter, supply_limit} = ticket;

    const showSettings = show_end_date || show_supply;
  
  return (
    <div className="ticket-card">
      <div className="left-container">
        <Subtitle small>{title}</Subtitle>

        {description && <Description>{description}</Description>}

        {showSettings &&
          <OfferCardSettings 
            end_date={show_end_date ? end_date : null} 
            issued_counter={issued_counter} 
            supply_limit={show_supply ? supply_limit: null} />
        }
      </div>

      <ButtonGroup
        product={ ticket }
        onAction={ onAction }
      />
    </div>
  );
};

TicketCard.propTypes = {
  ticket: PropTypes.object,
  onAction: PropTypes.func
};

export default TicketCard;