import React from "react";
import PropTypes from "prop-types";

import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

const Title = ({title, onClose}) => {
  
  return (
    <DialogTitle>
      End Sales for {title}
      <IconButton
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 8,
          top: 12,
          color: "black",
        }}
      >
        <CloseIcon />
      </IconButton>
    </DialogTitle>
  );
};

Title.propTypes = {
  title: PropTypes.string,
  onClose: PropTypes.func,
};

export default Title;
