import React from "react";
import PropTypes from "prop-types";

import Subtitle from "ui/text/Subtitle";

import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";

const platforms = ["Shotgun", "Xceed"];

const PlatformSelector = ({ data, onDataChange }) => {
  const handlePlatformSelection = (event) => {
    const newData = { ...data, platform: event.target.value };
    onDataChange(newData);
  };

  const { platform, event_id } = data;

  return (
    <>
      <Subtitle small>Step 2: Select Platform</Subtitle>
      <FormControl>
        <InputLabel size="small" color="warning">
          Select Platform
        </InputLabel>
        <Select
          label="Currency"
          name="currency"
          color="warning"
          size="small"
          fullWidth
          value={platform}
          onChange={handlePlatformSelection}
          disabled={!event_id}
        >
          {Object.values(platforms).map((option) => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </>
  );
};

PlatformSelector.propTypes = {
  data: PropTypes.object,
  onDataChange: PropTypes.func,
};

export default PlatformSelector;
