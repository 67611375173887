import React, { useCallback, useEffect, useState } from 'react';
import { useController } from '@rest-hooks/react';
import { useParams, useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';

import AdminResource from 'resources/admin/SearchableFilterResource';
import OrganizationResource from 'resources/organization/SearchableFilterResource';
import CheckinResource from 'resources/checkin/SearchableFilterResource';

import { useErrorController } from 'utils/useErrorController';

import CircularProgress from '@mui/material/CircularProgress';
import { Autocomplete, TextField } from '@mui/material';

import { debounce } from 'lodash';

const requestResource = {
  "admin": AdminResource,
  "organization": OrganizationResource,
  "checkin": CheckinResource
}

const getRequestParams = (query, label, organizationId, eventId, eventUuid, sessionId) => {

  const commonParams = {
    query: query,
    entity: label
  };

  if (organizationId) commonParams.organization_id = organizationId;

  if (eventId) commonParams.event_id = eventId;

  if (eventUuid) commonParams.event_uuid = eventUuid;

  if (sessionId) commonParams.session_id = sessionId;

  return commonParams;
};

const getId = (params, searchParams, key) => {
  return params[key] ? parseInt(params[key]) : searchParams.has(key) ? parseInt(searchParams.get(key)) : null;
};

const SearchableFilter = ({ label, onChange, customLabel = null, fullwidth = false }) => {

  const [options, setOptions] = useState([])
  const {loading, setLoading, handleError} = useErrorController();

  const params = useParams();
  const history = useHistory();

  const searchParams = new URLSearchParams(window.location.search);

  const organizationId = getId(params, searchParams, 'organizationId');
  const eventId = getId(params, searchParams, 'eventId');
  const eventUuid = params.eventUuid;
  const sessionId = getId(params, searchParams, 'sessionId');

  const context = params.organizationId ? "organization" : params.eventUuid ? "checkin" : "admin";

  const renderOption = (props, option) => <li {...props}>{option.identifier}</li>;
  const renderInput = (params) => 
    <TextField 
      {...params}
      label={customLabel || `Filter by ${label}`} 
      color="warning"
      InputProps={{
        ...params.InputProps,
        endAdornment: (
          <>
            {loading ? <CircularProgress color="inherit" size={20} /> : null}
            {params.InputProps.endAdornment}
          </>
        ),
      }}
    />;

  const {fetch} = useController();

  const debouncedSearch = useCallback(
    debounce(async (query) => {
      try {
        const response = await fetch(requestResource[context].list(), getRequestParams(query, label, organizationId, eventId, eventUuid, sessionId));
        setOptions(response);
      } catch (error) {
        handleError(error);
      } finally {
        setLoading(false);
      }
    }, 1000),
    [fetch, context, label, params, searchParams]
  );

  const updateSearchParam = (label, newValue) => {
    if (newValue) {
      searchParams.set(label, newValue.id);
    } else {
      searchParams.delete(label);
    }

    history.replace({ search: `?${searchParams.toString()}` });
  };

  const handleSelection = (event, newValue) => {
    updateSearchParam(`${label}Id`, newValue);
    onChange(`${label}_id`, newValue?.id);
  };

  const handleQueryChange = (event, newValue, reason) => {
    if (reason === 'input') {
      setLoading(true);
      debouncedSearch(newValue);
    }
  };

  const handleOpen = () => {
    setLoading(true);
    debouncedSearch('');
  };

  useEffect(() => {
    history.push({});
  }, []);

  return (
    <Autocomplete
      size="small"
      selectOnFocus
      blurOnSelect
      clearOnBlur
      clearOnEscape
      handleHomeEndKeys
      openOnFocus
      loading={loading}
      sx={fullwidth ? null : { width: 275 }}
      options={options}
      onChange={handleSelection}
      onOpen={handleOpen}
      onInputChange={handleQueryChange}
      renderOption={renderOption}
      renderInput={renderInput}
      getOptionLabel={(option) => option.identifier}
    />
  );
};

SearchableFilter.propTypes = {
    label: PropTypes.string,
    customLabel: PropTypes.string,
    onChange: PropTypes.func,
    fullwidth: PropTypes.bool
};

export default SearchableFilter;
