import React from "react";
import PropTypes from "prop-types";

import { getPageName } from "utils/getPagesName";

import IconButton from "@mui/material/IconButton";

import "./styles.css";

const SimpleTable = ({ columns, rows, onClick = null, Icon = null }) => {
  const renderCellContent = (row, column) => {
    const cellValue = row[column.value];
    if (column.value === "page") return getPageName(cellValue);
    return cellValue;
  };

  const renderTableCell = (row, column, index) => {
    return (
      <td key={index} className="simple-table_cell">
        {onClick && index === 0 ? (
          <div>
            <IconButton size="small" sx={{ padding: 0 }} onClick={() => onClick(row[column.value])}>
              {Icon}
            </IconButton>
            {renderCellContent(row, column)}
          </div>
        ) : (
          renderCellContent(row, column)
        )}
      </td>
    );
  };

  return (
    <table className="simple-table">
      <thead className="simple-table_head">
        <tr className="simple-table_head-row">
          {columns.map((column, index) => (
            <th key={index} className="simple-table_head-cell">
              {column.label}
            </th>
          ))}
        </tr>
      </thead>
      <tbody className="simple-table_body">
        {rows.map((row, rowIndex) => (
          <tr key={rowIndex} className="simple-table_row">
            {columns.map((column, colIndex) =>
              renderTableCell(row, column, colIndex)
            )}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

SimpleTable.propTypes = {
  columns: PropTypes.array,
  rows: PropTypes.array,
  onClick: PropTypes.func,
  Icon: PropTypes.node,
};

export default SimpleTable;
