import React from 'react';
import PropTypes from 'prop-types';

import ErrorPage from './ErrorPage';

const NotFound = ({context}) => {

  const isProfileContext = context === 'profile';
  
  const page = {
    title: `${isProfileContext ? "Club" : "Page"} Missing`,
    description: `Seems like we've made a detour. The ${isProfileContext ? "club" : "page"} you're looking for has evaporated into thin ether! But don't let that stop the music.`,
  };

  if (isProfileContext) {
    page.secondaryButtonAction = () => window.location.href = '/';
    page.secondaryButtonLabel = 'Claim your Club';
  }

  return (
    <ErrorPage page={page} />
  );
};

NotFound.propTypes = {
  context: PropTypes.string,
};

export default NotFound;
