import React, { useState } from 'react';
import PropTypes from 'prop-types';

import TicketResource from 'resources/organization/TicketResource';

import ActionButtons from './ActionButtons';

import Button from 'ui/Button';

import ConfirmModal from 'components/ConfirmModal';
import EmailEditor from "components/EmailEditor";
import EmailConfirmModalContent from "components/EmailConfirmModalContent";

import formattedDate from 'utils/formattedDate';
import isMobileDevice from 'utils/mobileAndTabletCheck';
import useEndSalesController from 'utils/useEndSalesController';
import { formatPrice } from 'utils/rewards';
import { useError } from 'utils/useErrorController';
import { useEmailController } from "utils/useEmailController";

import { Avatar, ListItem, ListItemText } from '@mui/material';
import LibraryAddOutlinedIcon from '@mui/icons-material/LibraryAddOutlined';

import moment from 'moment';

const ListItemComponent = ({ event, organizationId, loading, history, onDelete, onCheckin  }) => {

  const [confirmEmailSend, setConfirmEmailSend] = useState(false);
  const [sessionId, setSessionId] = useState(null);

  const {title, status, start_date, has_ticket, sold_tickets_count, total_revenue, currency, calculated_capacity} = event;

  const transformedTitle = `${title?.slice(0, 40)}${title?.length > 40 ? "..." : ""}`;
  
  const listItemStyle = {
    backgroundColor: "#ffff", 
    margin: '16px 0px', 
    borderRadius: 'var(--radius)', 
    display: 'flex',
    justifyContent: 'space-between',
  };

  const containerStyle = {
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  };

  const isMobile = isMobileDevice();

  const eventDate = moment(start_date).utc().startOf('day');
  const today = moment().utc().startOf('day');
  const showCheckInButton = eventDate.isSame(today) && has_ticket && status === 'published';

  const {
    email,
    setEmail,
    editingEmail,
    handleEmailSendRequest,
    handleEmailSend,
    handleEditEmailClick,
    handleEmailCancel,
  } = useEmailController(
    organizationId,
    setConfirmEmailSend,
    event.id,
    sessionId,
  );

  const {
    openEndSalesModal,
    EndSalesModalComponent, 
    handleEndSalesModalOpen
  } = useEndSalesController(
    event, 
    TicketResource, 
    {organization_id: organizationId, event_id: event.id}
  );

  const {handleError} = useError();

  const ticketCount = sessionId ? event.sessions.find(session => session.id === sessionId).sold_tickets_count : event.sold_tickets_count;

  return (
    <>
      <ListItem 
        alignItems="flex-start"
        sx={{...listItemStyle, flexDirection: isMobile ? 'column' : 'row' }} >

        <div style={containerStyle}>

          <Avatar variant="rounded" src={event.image_url} />

          <ListItemText 
            primary={transformedTitle} 
            secondary={formattedDate(event.start_date, 'date')} />
            
        </div>

        <div style={{...containerStyle, alignSelf: isMobile ? 'flex-end' : 'center'}}>

          {showCheckInButton &&
            <Button 
                small 
                className={"chip-like"}
                icon={LibraryAddOutlinedIcon}
                onClick={() => history.push(`/organizations/${organizationId}/events/${event.id}/tickets`)} disabled={loading}>
                    Check In
            </Button>
          }

          {status === 'published' &&
              <ListItemText 
                sx={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end', minWidth: 'fit-content'}}
                primary={`${sold_tickets_count} / ${calculated_capacity}`} 
                secondary={formatPrice(total_revenue, currency, true)} />            
          }

          <ActionButtons
            event={event} 
            loading={loading} 
            organizationId={organizationId} 
            showCheckInButton={showCheckInButton}
            history={history}
            handleConfirmDeleteModal={onDelete}
            openCheckinCredentialsModal={onCheckin}
            openEmailModal={handleEditEmailClick}
            openEndSalesModal={handleEndSalesModalOpen}
          />

        </div>
      </ListItem>

      {editingEmail && (
        <EmailEditor
          object={email}
          onObjectChange={setEmail}
          onEmailSendRequest={handleEmailSendRequest}
          onEmailCancel={handleEmailCancel}
          onError={handleError}
          autoSelect={true}
          event={event}
          onSessionSelect={setSessionId}
        />
      )}

      {confirmEmailSend && (
        <ConfirmModal
          title={"Send email?"}
          open={confirmEmailSend}
          setOpen={setConfirmEmailSend}
          onConfirm={handleEmailSend}
        >
          {
            <EmailConfirmModalContent
              content={email?.content}
              subject={email?.subject}
              ticketCount={ticketCount}
            />
          }
        </ConfirmModal>
      )}

      {openEndSalesModal && 
        <EndSalesModalComponent />}
    </>
  );
};

ListItemComponent.propTypes = {
  event: PropTypes.object,
  organizationId: PropTypes.number,
  loading: PropTypes.bool,
  history: PropTypes.object,
  onDelete: PropTypes.func,
  onCheckin: PropTypes.func,
  onEmailSendRequest: PropTypes.func
};

export default ListItemComponent;