import React, { useEffect } from 'react';
import NewsletterResource from 'resources/organization/NewsletterResource';

import DataSelectionTable from 'components/DataTable/DataSelectionTable';

import { newslettersColumns } from 'utils/tables/columns/newslettersColumns';
import usePaginationAndFilteringController from 'utils/usePaginationAndFilteringController';
import { useParams } from 'react-router-dom';

const NewslettersTabContent = () => {

  const params = useParams();
  const organizationId = parseInt(params.organizationId);

  const {
    objectState, 
    fetchData,
    handlePageChange
  } = usePaginationAndFilteringController({
      fetchDataResource: NewsletterResource,
      fetchDataParams: { organization_id: organizationId },
  })

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div style={{ flexGrow: 1 }}>
        <DataSelectionTable records={objectState.filteredObjects}
                            columns={newslettersColumns}
                            serverPaginationProps={{
                              paginationServer: true,
                              paginationTotalRows: objectState.totalCount,
                              page: objectState.page,
                              onChangePage: handlePageChange
                            }}
                            subHeader={false}
        />
    </div>
  );
};

export default NewslettersTabContent;