import React, { useState } from "react";
import PropTypes from "prop-types";
import { useController } from '@rest-hooks/react';
import { useLocation } from "react-router-dom";

import PageElementResource from 'resources/Profile/PageElementResource';
import ProfileResource from 'resources/Profile/ProfileResource';
import MembershipResource from 'resources/Profile/MembershipResource';
import ProductResource from 'resources/Profile/ProductResource';
import EventResource from "resources/Profile/EventResource";
import TicketResource from "resources/Profile/TicketResource";

import AuthModal from 'components/authForms/AuthModal';

import Menu from "./Menu";

import UserName from 'utils/localStorage/UserName';
import { useLogout } from 'utils/useLogout';

import './styles.css';

import Avatar from "@mui/material/Avatar";
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import { Tooltip } from "@mui/material";

import { t } from 'i18n';

const SwipeableTemporaryDrawer = ({ points, slug}) => {
  const [state, setState] = useState({ right: false });
  const [openAuthModal, setOpenAuthModal] = useState(false);

  const logout = useLogout();
  const {fetch} = useController();
  const { pathname } = useLocation();

  // we need to know if we are in the the event single page with or without a secret ticket to refresh data after auth change
  // only by refreshing the data after auth change will we be able to start an order with the correct discount shown in the order summary
  // we cannot use useParams inside the Swippeable drawer, hence the following method
  const eventPattern = /\/events\/([^/]+)(?:\/([^/]+))?/;
  const match = pathname.match(eventPattern);
  const [eventSlug, ticketSlug] = match  ? [match[1], match[2] || null] : [null, null];

  const inContentPage = pathname.includes('/content') || pathname.includes('/tags');

  const userName = UserName.read();
  const isLoggedIn = !!userName;

  const label = isLoggedIn ? t('literal:logout') : t('literal:signUp');
  const action = () => isLoggedIn ? handleLogout() : setOpenAuthModal(true);
  
  const toggleDrawer = (anchor, open) => (event) => {
    if (event && event.type === "keydown" && (event.key === "Tab" || event.key === "Shift")) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const handleSuccessfulAuth = async () => {
    await refreshData();
    if (inContentPage) window.location.reload(); // no need to refresh content as that is being fetched on render an not using useSuspense
    setOpenAuthModal(false);
  };

  const handleLogout = async () => {
    await logout(false, false);
    await refreshData();
    if (inContentPage) window.location.reload(); // no need to refresh content as that is being fetched on render an not using useSuspense
  };

  const refreshData = async () => {
    //helps improve performance by executing Promises concurrently instead of sequentially
    await Promise.all([
      fetch(ProfileResource.detail(), {id: slug}),
      fetch(MembershipResource.list(), {organization_slug: slug}),
      fetch(ProductResource.list(), {organization_slug: slug}),
      fetch(PageElementResource.detail(), {organization_slug: slug})
    ]);
    
    //if in the single event page with secret ticket
    if (ticketSlug) {
      await fetch(TicketResource.detail(), {organization_slug: slug, event_slug: eventSlug, id: ticketSlug});

      //if in the single event page without secret ticket
    } else if (eventSlug) {
      await fetch(EventResource.detail(), {organization_slug: slug, id: eventSlug});
    }
  };

  return (
    <React.Fragment key={"right"}>
      <IconButton onClick={toggleDrawer("right", true)} className="menu-button">
        {userName 
          ? <Tooltip title={userName}>
              <Avatar alt={userName} src="/static/images/avatar/1.jpg" />
            </Tooltip>
          : <MenuIcon />}
      </IconButton>

      <SwipeableDrawer
        anchor={"right"}
        open={state["right"]}
        onClose={toggleDrawer("right", false)}
        onOpen={toggleDrawer("right", true)}
        className="swipeable-drawer"
      >
        <Menu
          anchor={"right"}
          label={label}
          points={points}
          action={action}
          toggleDrawer={toggleDrawer}
          userName={userName}
        />
      </SwipeableDrawer>

      <AuthModal open={ openAuthModal }
               onClose={ () => setOpenAuthModal(false) }
               done={ handleSuccessfulAuth }
               organizationSlug={ slug } />
               
    </React.Fragment>
  );
};

SwipeableTemporaryDrawer.propTypes = {
  points: PropTypes.number,
  slug: PropTypes.string,
};

export default SwipeableTemporaryDrawer;
