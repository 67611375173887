import React from 'react';
import PropTypes from 'prop-types';

import CardGrid from './CardGrid';

import TagsFilter from 'components/TagsFilter';

const ptProducts = [24, 480, 481];

const asaFilter = (product) => {
  if (navigator.language.includes('pt')) {
    return ptProducts.includes(product.id);
  } else {
    return !ptProducts.includes(product.id);
  }
};

const ProductsList = ({products, onAction, organizationSlug, organizationTags, activeTag}) => {

  const showTags = organizationTags && organizationTags.length > 0;

  const filteredProducts =  organizationSlug == 'asa-perafita'
                              ? products.filter(asaFilter)
                              : products

  return (
    <div className='stores-content'>
      {showTags &&
        <TagsFilter
          organizationSlug={organizationSlug}
          organizationTags={organizationTags}
          activeTag={activeTag} />}

      <CardGrid
        products={filteredProducts}
        organizationTags={organizationTags}
        onAction ={onAction} />
    </div>
  );
};

ProductsList.propTypes = {
  products: PropTypes.array,
  onAction: PropTypes.func,
  organizationSlug: PropTypes.string,
  organizationTags: PropTypes.array,
  activeTag: PropTypes.string,
};

export default ProductsList;
