const getPathSegments = (url) => {
  if (!url) return [];
  const path = url.replace(process.env.REACT_APP_API_HOST, '');
  return path.split('/').filter(Boolean);
};

export const isOrganizationFetch = (url) => {
  const segments = getPathSegments(url);
  return segments.length === 2 && segments[0] === 'profile';
};

export const getOrganizationSlug = (url) => {
  const segments = getPathSegments(url);

  if (segments[0] !== 'profile') return null
  return segments.length >= 2 ? segments[1] : null;
};
