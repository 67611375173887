import React from "react";
import PropTypes from "prop-types";

import StatCard from "components/StatCard";
import { formatPrice } from "utils/rewards";

const Stats = ({ products }) => {
  const product = products?.[0];

  const stat1 = product ?  formatPrice(product.total_revenue, product.currency) : 0;
  
  const stat2 = product ? product.total_count : 0;

  return (
    <div className="event-stats-grid grid custom-margin">

      <StatCard type="number"
        color="rgb(54, 162, 235)"
        highlight={ stat1 }
        title="Total Revenue" />

      <StatCard type="number"
        color="rgb(255, 205, 86)"
        highlight={ stat2 }
        title="Sold Products Count" />

    </div>
)};

Stats.propTypes = {
  products: PropTypes.array
};

export default Stats;