import React from 'react';
import PropTypes from 'prop-types';

import Title from './Title';
import Description from './Description';
import ExtendedDescription from './ExtendedDescription';
import CategorySelector from './CategorySelector';

import TagSelector from 'components/TagSelector';

import FormSection from 'ui/FormSection';


const Details = ({ formTitle , title, description, category, handleChange, object, onObjectChange, organizationId, isTicket}) => {
  return (
    <FormSection title={formTitle}>
      <Title
          title={title}
          onChange={handleChange}
          object={object}
          onObjectChange={onObjectChange}
          isTicket={isTicket}
          />

      <Description
          description={description}
          onChange={handleChange}
          isTicket={isTicket}
          />

      { !isTicket &&
      <>
          <ExtendedDescription
              object={object}
              onObjectChange={onObjectChange} />

          <div className='row'>
              <CategorySelector
                  category={category}
                  onChange={handleChange} />

              <TagSelector
                  object={object}
                  onObjectChange={onObjectChange}
                  objectType={"product"}
                  organizationId={organizationId} />
          </div>
      </>
      }
  </FormSection>
  );
};

Details.propTypes = {
    formTitle: PropTypes.string,
    title: PropTypes.string,
    description: PropTypes.string,
    category: PropTypes.string,
    handleChange: PropTypes.func,
    object: PropTypes.object,
    onObjectChange: PropTypes.func,
    organizationId: PropTypes.string,
    isTicket: PropTypes.bool
};

export default Details;