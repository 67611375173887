import React, {Suspense, useEffect} from 'react';
import PropTypes from 'prop-types';
import { Route, Switch } from 'react-router-dom';

import About from './About';
import Content from './Content';
import Memberships from './Memberships';
import Store from './Store';
import Events from './Events';
import Notion from './Notion';
import Redirect from './Redirect';
import Sidebar from './Sidebar';
import Next from './Events/Next';

import ContentMenu from 'ui/ContentMenu';

import isMobileDevice from 'utils/mobileAndTabletCheck';
import { FreeSubscriptionProvider } from 'utils/freeSubscriptionController';
import { useSocialMetatags } from 'utils/socialMetatagsController';
import generateMetatags from 'utils/generateMetatags';

import './styles.css';

const Body = ({ organization }) => {

  const isDesktop = !isMobileDevice();
  const isAbout = window.location.pathname === `/${organization?.slug}` || window.location.pathname === `/${organization?.slug}/about`;
  const showSidebar = isDesktop || isAbout;

  const organization_title = organization?.title;
  const organization_logo = organization?.image;

  const { setSocialMetatags } = useSocialMetatags();

  useEffect(() => {
    generateMetatags('', setSocialMetatags, organization_title, organization_logo);
  }, [organization]);

  const componentWithProps = (props, Component) => <Component {...props} organization_title={organization_title} organization_logo={organization_logo} />

  return (
    <div className="profile-master">
      <FreeSubscriptionProvider>

      {showSidebar && <Sidebar organization={ organization } />}

      <div className="profile_content">
        {isDesktop && <ContentMenu organization={ organization } />}

        <div className='profile_content__body'>
            <Suspense fallback={ <div style={{ width: '100%', height: '100vh'}}></div> }>
              <Switch>
                  <Route path={ `/:organizationSlug/memberships` } render={(props) => componentWithProps(props, Memberships)} />
                  <Route path={ `/:organizationSlug/events/next` } render={(props) => componentWithProps(props, Next)} />
                  <Route path={ `/:organizationSlug/events/:eventSlug/:ticketSlug` } render={(props) => componentWithProps(props, Events)} />
                  <Route path={ `/:organizationSlug/events/:eventSlug` } render={(props) => componentWithProps(props, Events)} />
                  <Route path={ `/:organizationSlug/events` } render={(props) => componentWithProps(props, Events)} />
                  <Route path={ `/:organizationSlug/content/:postSlug` } render={(props) => componentWithProps(props, Content)} />
                  <Route path={ `/:organizationSlug/content` } render={(props) => componentWithProps(props, Content)} />
                  <Route path={ `/:organizationSlug/store/:productSlug` } render={(props) => componentWithProps(props, Store)} />
                  <Route path={ `/:organizationSlug/store` } render={(props) => componentWithProps(props, Store)} />
                  <Route path={ `/:organizationSlug/about` } component={ About } />
                  <Route path={ `/:organizationSlug/register` } component={ About } />
                  <Route path={ `/:organizationSlug/more` } component={ Notion } />
                  <Route path={ `/:organizationSlug` } component={ Redirect } />
              </Switch>
            </Suspense>
        </div>

        {!isDesktop && <ContentMenu organization={ organization } />}
      </div>

      </FreeSubscriptionProvider>
    </div>
  );
};

Body.propTypes = {
    organization: PropTypes.object,
};

export default Body;
