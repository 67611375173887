import React from "react";
import PropTypes from "prop-types";

import StatCard from "components/StatCard";
import { formatPrice } from "utils/rewards";

const Stats = ({ tickets }) => {
  const ticket = tickets?.[0];

  const stat1 = ticket ? formatPrice(ticket.total_revenue, ticket.currency) : 0;

  const stat2 = ticket ? ticket.total_count : 0;

  return (
    <div className="event-stats-grid grid custom-margin">
      <StatCard
        type="number"
        color="rgb(54, 162, 235)"
        highlight={stat1}
        title="Total Revenue"
      />

      <StatCard
        type="number"
        color="rgb(255, 205, 86)"
        highlight={stat2}
        title="Sold Tickets Count"
      />
    </div>
  );
};

Stats.propTypes = {
  tickets: PropTypes.array,
};

export default Stats;
