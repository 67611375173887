import React from 'react'
import PropTypes from 'prop-types'
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import FormSection from 'ui/FormSection';

const SessionSelector = ({options, onChange, slim=false, showTitle=true}) => {

  return (
    <FormSection title={showTitle ? "Session" : ""} slim={slim}>
        <FormControl>
        <InputLabel size="small" color="warning">Select Session</InputLabel>
        <Select
            label="Select Session"
            name="visible"
            color="warning"
            size="small"
            fullWidth
            onChange={ onChange }
        >
            { options.map((option) => {
                return (
                <MenuItem key={option.id} value={option.id}>
                    {option.title}
                </MenuItem>
                )
            }
            )}
        </Select>
        </FormControl>
    </FormSection>
  );
};

SessionSelector.propTypes = {
    options: PropTypes.array,
    onChange: PropTypes.func,
    slim: PropTypes.bool,
    showTitle: PropTypes.bool
};

export default SessionSelector;
