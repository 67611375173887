import React, { useState } from "react";

import EndSalesModal from "components/EndSalesModal";

const useEndSalesController = (event, TicketResource, ticketResourceParams) => {
  
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);

  const EndSalesModalComponent = () => {
    return (
      <EndSalesModal
        event={event}
        TicketResource={TicketResource}
        ticketResourceParams={ticketResourceParams}
        open={open}
        onOpenChange={setOpen}
      />
    );
  };

  return {
    openEndSalesModal: open,
    handleEndSalesModalOpen: handleOpen,
    EndSalesModalComponent,
  };
};

export default useEndSalesController;
