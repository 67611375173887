import React from 'react';
import PropTypes from 'prop-types';

import Button from 'ui/Button';

import DialogActions from '@mui/material/DialogActions';

const Actions = ({ data, handleSubmit, onClose }) => {

  const { event_id, platform, file } = data;

  return (
    <DialogActions>
      <Button fullwidth secondary onClick={onClose}>Cancel</Button>
      <Button fullwidth onClick={handleSubmit} disabled={!event_id || !platform || !file}>Submit</Button>
    </DialogActions>
  );
};

Actions.propTypes = {
  data: PropTypes.object,
  handleSubmit: PropTypes.func,
  onClose: PropTypes.func
};

export default Actions;