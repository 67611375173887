import React, { useState } from 'react';
import PropTypes from 'prop-types';

import copyToClipboard from 'utils/copyToClipboard';
import { useToast } from 'utils/context/ToastContext';

import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutline';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import PreviewIcon from '@mui/icons-material/Preview';
import LocalActivityIcon from '@mui/icons-material/LocalActivity';
import KeyIcon from '@mui/icons-material/Key';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import EmailIcon from '@mui/icons-material/Email';
import EventBusyIcon from '@mui/icons-material/EventBusy';

import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';

const ActionButtons = ({event, loading, showCheckInButton, organizationId, history, handleConfirmDeleteModal, openCheckinCredentialsModal, openEmailModal, openEndSalesModal}) => {

    const { status, id, external_url, has_ticket, ticketing, single, over, has_sold_tickets } = event;

    const { setOpen, setMessage, setSeverity } = useToast();

    const draft = status === 'draft';

    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);

    const handleMenuOpen = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
      setAnchorEl(null);
    };

    const handlePreview = () => {
      window.open(external_url, '_blank');
      handleMenuClose();
    };

    const handleUrlCopy = () => {
      copyToClipboard(external_url, setOpen, setSeverity, setMessage);
      handleMenuClose();
    };

    const actionButtonsData = [
        {
          label: 'Copy URL',
          icon: ContentCopyIcon,
          action: handleUrlCopy,
          disabled: loading || draft,
        },
        {
          label: 'Preview',
          icon: PreviewIcon,
          action: handlePreview,
          disabled: loading,
        },
        {
          label: 'Edit',
          icon: EditIcon,
          action: () => {
            history.push(`/organizations/${organizationId}/events/${id}/edit`);
            handleMenuClose();
          },
          disabled: loading,
        },
        {
          label: 'Delete',
          icon: DeleteIcon,
          action: () => {
            handleConfirmDeleteModal(event);
            handleMenuClose();
          },
          disabled: loading || has_sold_tickets,
        },
        {
          label: 'Send Email to guests',
          icon: EmailIcon,
          action: () => {
            openEmailModal();
            handleMenuClose();
          },
          disabled: loading || draft || !has_sold_tickets,
        },
      ];

    if(ticketing){
        actionButtonsData.unshift(
          {
            label: 'Check In URL && Code',
            icon: KeyIcon,
            action: () => {
              openCheckinCredentialsModal(event);
              handleMenuClose();
            },
            disabled: loading || draft,
          },
        )
    }

    if (single) {
      actionButtonsData.unshift(
        {
          label: 'Add Ticket',
          icon: AddCircleOutlineOutlinedIcon,
          action: () => {
            history.push(`/organizations/${organizationId}/perks/new?eventId=${id}`);
            handleMenuClose();
          },
          disabled: loading || over,
        },
      );
    }

    if(has_ticket && !showCheckInButton) {
      const ticketsAction = {
        label: 'Tickets',
        icon: LocalActivityIcon,
        action: () => {
          history.push(`/organizations/${organizationId}/events/${id}/tickets`);
          handleMenuClose();
        },
        disabled: loading || draft || !has_sold_tickets,
      }

      const endSalesAction = {
        label: 'End Sales',
        icon: EventBusyIcon,
        action: () => {
          openEndSalesModal();
          handleMenuClose();
        },
        disabled: loading || draft,
      }

      actionButtonsData.unshift( ticketsAction, endSalesAction );
    }

  return (
    <div>      
      <IconButton onClick={handleMenuOpen} color='warning'>
        <MoreVertIcon />
      </IconButton>
      <Menu anchorEl={anchorEl} open={open} onClose={handleMenuClose}>
        {actionButtonsData.map((data) => (
          <MenuItem
            key={data.label}
            onClick={data.action}
            disabled={data.disabled}
          >
            <ListItemIcon>
              <data.icon color='warning'/>
            </ListItemIcon>
            <ListItemText primary={data.label} />
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};

ActionButtons.propTypes = {
    event: PropTypes.object,
    loading: PropTypes.bool,
    showCheckInButton: PropTypes.bool,
    organizationId: PropTypes.number,
    history: PropTypes.object,
    handleConfirmDeleteModal: PropTypes.func,
    openCheckinCredentialsModal: PropTypes.func,
    openEmailModal: PropTypes.func,
    openEndSalesModal: PropTypes.func
};

export default ActionButtons;
