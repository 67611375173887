import React from 'react';
import PropTypes from 'prop-types';

import NotFound from './NotFound';
import Internal from './Internal';
import Unprocessable from './Unprocessable';

import { getOrganizationSlug, isOrganizationFetch } from './helpers';

const fallbackComponents = {
  422: Unprocessable,
  404: NotFound,
  500: Internal,
};

const handleOrganizationRedirect = (organizationSlug) => {
  window.location.href = `/${organizationSlug}`;
  return true;
};

const handleAuthenticationError = () => {
  localStorage.clear();
  window.location.href = '/login';
};

const NetworkError = ({error}) => {

  const { status, response } = error;

  if (error.status === 401) {
    handleAuthenticationError();
    return null;
  }

  if (status === 404 && response.url) {

    // example: /profile/invalid_slug
    if (isOrganizationFetch(response.url)) {
      return <NotFound error={error} context="profile"/>;
    }

    // example: /profile/organization_slug/events/invalid_event_slug
    const organizationSlug = getOrganizationSlug(response.url);
    if (organizationSlug) {
      handleOrganizationRedirect(organizationSlug);
      return null;
    }
  }

  const FallbackComponent = fallbackComponents[error.status];
  return (
    <FallbackComponent error={error} />
  );
};

NetworkError.propTypes = {
    error: PropTypes.object,
};

export default NetworkError;
