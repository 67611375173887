import React from "react";
import PropTypes from "prop-types";

import FormSection from "ui/FormSection";

import DialogContent from "@mui/material/DialogContent";
import OrganizationAndEventSelector from "./OrganizationAndEventSelector";
import PlatformSelector from "./PlatformSelector";
import FileUploader from "./FileUploader";

const Content = ({ data, onDataChange }) => {
  return (
    <DialogContent>
      <FormSection slim>
        <OrganizationAndEventSelector data={data} onDataChange={onDataChange} />

        <PlatformSelector data={data} onDataChange={onDataChange} />

        <FileUploader data={data} onDataChange={onDataChange} />
      </FormSection>
    </DialogContent>
  );
};

Content.propTypes = {
  data: PropTypes.object,
  onDataChange: PropTypes.func,
};

export default Content;
