import AuthToken    from 'utils/localStorage/AuthToken';

const API_HOST = process.env.REACT_APP_API_HOST;

const constructUrl = (params) => {
  const {event_uuid, event_id, organization_id, session_id, result} = params;

  const queryParams = new URLSearchParams();
  if (session_id) queryParams.append('session_id', session_id);

  let rootUrl;

  if (event_uuid) {
    queryParams.append('event_uuid', event_uuid);
    rootUrl = 'checkin/tickets';

  } else if (organization_id && event_id) {
   rootUrl = `organizations/${organization_id}/events/${event_id}/tickets`
  }

  const queryString = queryParams.toString();
  return new URL(`${API_HOST}/${rootUrl}/${result}/${queryString ? `?${queryString}` : ''}`);
};

const constructInit = () => {
  const authToken = AuthToken.read();
  return {
    method: 'GET',
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${authToken}`,
    },
  };
};

export default async function fetchTicket (params) {

    const url = constructUrl(params);
    const init = constructInit();

    const response = await fetch(url, init);

    if (!response.ok) {
      throw `Network response error: ${response.status}`;
    }

    const parsed_response = await response.json();
    return parsed_response;
  }
